<template>
  <a-modal
    :title="$t('my.pwd.update')"
    :visible="isShow"
    :confirm-loading="confirming"
    width="600px"
    @ok="updatePassword"
    @cancel="handleCancel"
    destroy-on-close
    dialogClass="myDialog"
  >
    <a-form-model
      layout="horizontal"
      ref="pwd"
      :model="pwdForm"
      :rules="pwdRules"
      v-bind="formItemLayout"
    >
      <a-form-model-item :label="$t('my.pwd.oldPassword')" prop="originpwd">
        <a-input-password
          v-model="pwdForm.originpwd"
          :placeholder="$t('system.pleaseInput', [$t('my.pwd.oldPassword')])"
        />
      </a-form-model-item>
      <a-form-model-item :label="$t('my.pwd.newPassword')" prop="newpwd">
        <a-input-password
          v-model="pwdForm.newpwd"
          :placeholder="$t('system.pleaseInput', [$t('my.pwd.newPassword')])"
        />
      </a-form-model-item>

      <a-form-model-item
        :label="$t('my.contacts.passwordConfirm')"
        prop="confirmpwd"
      >
        <a-input-password
          v-model="pwdForm.confirmpwd"
          :placeholder="
            $t('system.pleaseInput', [$t('my.contacts.passwordConfirm')])
          "
        />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import myServer from "@/api/my";
export default {
  props: ["isShow"],
  data() {
    const validatePasswordConfirm = (rule, value, callback) => {
      value = value && value.trim();
      if (value != this.pwdForm.newpwd) {
        callback(new Error(this.$t("myValidate.my.3")));
      } else {
        callback();
      }
    };

    return {
      confirming: false,
      formItemLayout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
      },
      pwdForm: {
      
      },
      pwdRules: {
        originpwd: [
          {
            required: true,
            message: this.$t("system.pleaseInput", [
              this.$t("my.pwd.oldPassword"),
            ]),
            trigger: "blur",
          },
        ],
        newpwd: [
          {
            required: true,
            message: this.$t("system.pleaseInput", [
              this.$t("my.pwd.newPassword"),
            ]),
            trigger: "blur",
          },
        ],
        confirmpwd: [
          {
            required: true,
            message: this.$t("system.pleaseInput", [
              this.$t("my.contacts.passwordConfirm"),
            ]),
            trigger: "blur",
          },
          { validator: validatePasswordConfirm, trigger: "blur" },
        ],
      },
    
    };
  },
  computed: {

  },
  watch: {
    isShow(val) {
      if (val) {
      
      }
    },
  },
  mounted() {},
  methods: {

    handleOk() {},
    handleCancel() {
      this.doClose();
    },
    doClose(refresh = false) {
      this.$emit("close", refresh);
    },
    updatePassword() {
      this.$refs.pwd.validate((valid) => {
        if (valid) {
          this.confirming = true;
          let param = { ...this.pwdForm,userId:JSON.parse(localStorage.getItem("user")).userId};
        

          myServer
            .changePwd(param)
            .then((res) => {
              this.confirming = false;
              this.$message.success(this.$t("my.pwd.updateSuccess"));
              this.doClose(true)
            })
            .catch(() => {
              this.confirming = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .ant-input {
  border: none;
  border-bottom: 1px solid #c7c7c7;
  border-radius: 0;
  &:focus {
    box-shadow: none;
  }
}
/deep/ .ant-select-selection {
  border: none;
  border-bottom: 1px solid #c7c7c7;
  border-radius: 0;
}
</style>