import request from "@/api/request";

const myServer = {

    // updateCustomer: (params) =>
    //     request.post(
    //         "/adminapi/customer/updateCustomer",
    //         params
    //     ),

    getAccountList: () =>
        request({
            url: "/oms/getAccountsByCurrUser",
            method: "get",
        }),
    getOrgUser: (params) =>
        request({
            url: " /adminapi/baseUser/queryUser4Thub",
            method: "get",
            params: params,
        }),
    getOrgList: (params) =>
        request({
            url: " /adminapi/customer/getOrgList",
            method: "get",
            params: params,
        }),
    getRole4Thub: (params) =>
        request({
            url: " /adminapi/role/getRole4Thub",
            method: "get",
            params: params,
        }),
    addUser: (params) =>
        request.post(
            "/adminapi/baseUser/addOmsUser",
            params
        ),
    updateUser: (params) =>
        request.post(
            "/adminapi/baseUser/updateOmsUser",
            params
        ),
    deleteUser: (params) =>
        request.post(
            "/adminapi/baseUser/deleteOmsUser",
            params
        ),

    addOrg: (params) =>
        request.post(
            "/adminapi/customer/createSubCustomer",
            params
        ),
    updateOrg: (params) =>
        request.post(
            "/adminapi/customer/update4Thub",
            params
        ),
    queryMenuTree: params =>
        request({
            url: "/adminapi/menu/tree/8",
            method: "get",
            params: params,
        }),
    queryResources: params =>
        request({
            url: "/adminapi/resources/8",
            method: "get",
            params: params,
        }),
    addRole: (params) =>
        request.post(
            "/adminapi/role/addRole",
            params
        ),
    updateRole: (params) =>
        request.post(
            "/adminapi/role/updateRole",
            params
        ),
    getRoleDetailByRoleId: params =>
        request({
            url: "/adminapi/role/roleDetailByRoleId",
            method: "get",
            params: params,
        }),
    changePwd: (params) =>
        request.post(
            "/adminapi/baseUser/changePwd",
            params
        ),

    queryAddition: (params) =>
        request({
            url: '/omsapi/addition/queryAddition',
            method: 'get',
            params
        }),
};

export default myServer;
