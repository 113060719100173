import myServer from '@/api/my'

const state = {
    customerInfo: [],
};

const mutations = {


    setCustomerInfo(state, data) {
        state.customerInfo = data;
    },

};

const actions = {


    loadCustomerInfo({
        commit,
        state
    }, status) {
        return new Promise((resolve, reject) => {
            myServer.getAccountList().then((res) => {
                commit('setCustomerInfo', res.data??[])
                resolve(res)
            });
        })

    },
};

export default {
    state,
    mutations,
    actions,
};
