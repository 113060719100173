import moment from "moment";
const dateUtil = {
    format: (dateStr, f) => moment(dateStr).format(f ?? "DD / MMMM / YYYY"),
    getTZ: () => {
        var offset = new Date() + ""; //将时间格式转为字符串
        // console.log(offset); //  Mon Nov 02 2020 20:57:20 GMT-0600 (北美中部标准时间)
        let gmtIndex = offset.indexOf('GMT');
        let timeZ = offset.substring(gmtIndex + 3, gmtIndex + 8);
        // console.log(fghdfgd); //-0600    //拿到这个-0600也就是当前所在时区，然后转时间戳的
        timeZ = timeZ.replaceAll("0", "");
        return timeZ; //-6
    },
    getGMT: () => {
        var offset = new Date() + ""; 
        let gmtIndex = offset.indexOf('GMT');
        let timeZ = offset.substring(gmtIndex, gmtIndex + 8);
        return timeZ;
    }
}

export default dateUtil;