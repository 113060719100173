<template>
  <a-layout-header :style="{ position: 'fixed', zIndex: 1, width: '100%' }">
    <div class="logo">
      <router-link to="/home"
        ><img class="logo2" src="@/assets/logo.png" alt=""
      /></router-link>
    </div>

    <div class="funcRight">
      <!-- <a-popover>
        <template slot="content">
          <a-button type="link" @click="changeLocale('en')">English</a-button
          ><br />
          <a-button type="link" @click="changeLocale('zh-cn')"
            >简体中文</a-button
          >
        </template>
        <i class="iconfont tlicon-global" />
      </a-popover> -->
      <a-space :size="20">
        <a-icon
          type="lock"
          style="font-size: 16px; color: white; opacity: 0.65"
          @click="showModifyPasswordDialog"
        />
        <i class="iconfont tlicon-logout" @click="doLogout" />
      </a-space>
    </div>

    <a-menu
      theme="dark"
      mode="horizontal"
      :selectable="false"
      @click="onClickMenu"
    >
      <a-menu-item v-for="item in menuList" :key="item.id">
        {{ $t("menu." + item.code) }}
      </a-menu-item>
    </a-menu>

    <modify-pwd-dialog
      :isShow="isShowModifyPasswordDialog"
      @close="doCloseModifyPwdDialog"
    />
  </a-layout-header>
</template>
<script>
import mainServer from "@/api/index";
import ModifyPwdDialog from "./modifyPassword.vue";
export default {
  components: {
    ModifyPwdDialog,
  },
  data() {
    return {
      isShowModifyPasswordDialog: false,
    };
  },
  computed: {
    menuList() {
      let fullMenuList = this.$store.state.system.loginMenuList;
      let permissions = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user")).permissions
        : [];
      return fullMenuList.filter(
        (item) =>
          item.code != "preplanResultForPad" &&
          item.code != "preplanResultForMobile" &&
          permissions.indexOf(item.permission) >= 0
      );
    },
    hostName() {
      return location.hostname.toLowerCase();
    },
  },
  created() {},
  methods: {
    showModifyPasswordDialog() {
      this.isShowModifyPasswordDialog = true;
    },
    doCloseModifyPwdDialog(refresh){
       this.isShowModifyPasswordDialog = false;
       if(refresh){
        this.doLogout();
       }
    },
    onClickMenu(item) {
      if (item.key == 1) {
        this.$router.push("/order");
      } else if (item.key == 2) {
        this.$router.push("/appointment");
      } else if (item.key == 3) {
        this.$router.push("/bill");
      } else if (item.key == 4) {
        this.$router.push("/my");
      } else if (item.key == 5) {
        this.$router.push("/preplan");
      }
    },
    doLogout() {
      mainServer
        .logout({})
        .then(() => {
          localStorage.removeItem("ms_username");
          localStorage.removeItem("access_token");
          localStorage.removeItem("user");
          this.$router.push("/");
        })
        .catch(() => {
          localStorage.removeItem("ms_username");
          localStorage.removeItem("access_token");
          localStorage.removeItem("user");
          this.$router.push("/");
        });
    },
    changeLocale(language) {
      this.$i18n.locale = language;
      this.$store.commit("setLanguage", language);
      localStorage.setItem("locale", language);
      location.reload();
    },
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.logo {
  width: 120px;
  height: 31px;

  float: left;
  margin-right: 40px;

  img {
    width: 120px;
    height: 30px;
  }

  .logo2 {
    width: 120px;
    height: 22px;
  }
}

.funcRight {
  float: right;
  display: flex;
  align-items: center;
  height: 64px;

  /deep/ .ant-btn-link {
    color: #ffffff;
    opacity: 0.65;
  }

  /deep/ .ant-btn-link:hover {
    opacity: 1;
  }

  /deep/ .ant-divider-vertical {
    height: 20px;
    opacity: 0.65;
  }

  .iconfont::before {
    font-size: 18px;
    color: white;
    margin: 0 10px;
    cursor: pointer;
    opacity: 0.65;
  }

  .iconfont:hover {
    opacity: 1;
  }
}

/deep/ .ant-menu {
  text-align: left;
  font-size: 16px;

  .ant-menu-item {
    line-height: 64px;
    padding: 0 30px;
  }
}

.popContent {
  width: 830px;
  opacity: 0.95;
  padding: 50px 80px;

  .iconfont {
    font-size: 30px;
    color: #7fd8c0;
  }

  /deep/ .ant-collapse {
    border: none;

    .ant-collapse-item {
      border: none;
      background: white;

      .ant-collapse-header {
        border-bottom: 4px solid #e6e6e6;
        background-color: white;
        width: 200px;

        span {
          font-size: 24px;
          font-weight: 400;
          color: #333333;
          line-height: 24px;
          margin-left: 35px;
        }
      }

      .ant-collapse-content {
        border: none;
      }

      .ant-collapse-content-box {
        .item {
          font-size: 24px;
          font-weight: 400;
          color: #333333;
          line-height: 24px;
          margin: 30px 40px;
        }
      }
    }
  }
}
</style>
