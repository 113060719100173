import request from "@/api/request";

const mainServer = {

  // accountLogin: (params) =>
  //   request.post(
  //     `/login/login`,
  //     { ...params, captcha: "7F05217A03F53A1642565E8774CA794D" }
  //   ),

  accountLogin: ({ username, password }) =>
    request.post(
      `/adminapi/oauth/token?grant_type=password&scope=all&username=${username}&password=${password}&client_id=thuboms&client_secret=123`,
      {}
    ),

  logout: (params) =>
    request({
      url: "/adminapi/oauth/logout",
      method: "get",
      params: params,
    }),

  uploadFile: (params) =>
    request({
      url: '/adminapi/file/upload',
      method: 'post',
      data: params
    }),
  previewImg: (params) =>
    request({
      url: '/adminapi/file/preview',
      method: 'post',
      params: params
    }),
  queryRoleList: (params) =>
    request({
      url: " /adminapi/role/queryRole/8",
      method: "get",
      params: params,
    }),

  downloadFile: ({ fileName }) =>
    request({
      url: '/adminapi/file/download?fileName=' + fileName,
      method: 'post',
      data: {},
      responseType: 'blob'
    }),
};

export default mainServer;
