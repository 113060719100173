import Vue from 'vue'
import vueI18n from 'vue-i18n';

Vue.use(vueI18n)

let en = require("@/assets/languages/en.js");
let cn = require("@/assets/languages/zh-cn.js");

const messages = {
  'zh-cn': Object.assign(
    {},
    {
      ...cn,
    }
  ),
  'en': Object.assign(
    {},
    {
      ...en,
    }
  ),
};

const i18n = new vueI18n({
  locale: localStorage.getItem("locale") || 'en',
  fallbackLocale: 'en',
  messages,
});

export default i18n;
