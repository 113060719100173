import Vue from 'vue'
import VueRouter from 'vue-router'
import MainLayout from '../views/layout/MainLayout.vue'
import LoginLayout from '../views/layout/LoginLayout.vue'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
Vue.use(VueRouter)


NProgress.inc(0.2)
NProgress.configure({
  easing: 'ease',
  speed: 500,
  showSpinner: false
})

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/',
    name: '',
    component: MainLayout,
    children: [
      {
        path: '/login',
        name: 'Login',
        meta: {
          needLogin: false,
          title: 'Login'
        },
        component: () => import('../views/login/login.vue')
      },
      {
        path: '/service',
        name: 'Service',
        meta: {
          needLogin: false,
          title: 'Service'
        },
        component: () => import('../views/service/service.vue')
      },
      {
        path: '/about',
        name: 'About',
        meta: {
          needLogin: false,
          title: 'About'
        },
        component: () => import('../views/about/about.vue')
      },
      {
        path: '/contact',
        name: 'Contact',
        meta: {
          needLogin: false,
          title: 'Contact'
        },
        component: () => import('../views/contact/contact.vue')
      },
      {
        path: '/track',
        name: 'Track',
        meta: {
          needLogin: false,
          title: 'Track'
        },
        component: () => import('../views/track/track.vue')
      },
    ]
  },
  {
    path: '/',
    name: '',
    component: LoginLayout,
    children: [
      {
        path: '/order',
        name: 'Order',
        meta: {
          needLogin: true,
          title: 'Order',
          permission: 'order:view'
        },
        component: () => import('../views/order/order.vue')
      },
      {
        path: '/appointment',
        name: 'Appointment',
        meta: {
          needLogin: true,
          title: 'Appointment',
          permission: 'appointment:view' //TODO
        },
        component: () => import('../views/appointment/appointment.vue')
      },
      {
        path: '/bill',
        name: 'Bill',
        meta: {
          needLogin: true,
          title: 'Bill',
          permission: 'bill:view'
        },
        component: () => import('../views/bill/bill.vue')
      },
      {
        path: '/my',
        name: 'My',
        meta: {
          needLogin: true,
          title: 'My',
          permission: 'mine:view'
        },
        component: () => import('../views/my/my.vue')
      },
      {
        path: '/preplan',
        name: 'Preplan',
        meta: {
          needLogin: true,
          title: 'Preplan',
          permission: 'depot:view'
        },
        component: () => import('../views/depot/depot.vue')
      },
    ]
  },
  {
    path: '/home',
    name: 'Home',
    meta: {
      needLogin: false,
      title: 'HomePage'
    },
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/preplanResult',
    name: 'Result',
    meta: {
      needLogin: true,
      title: 'Preplan Result',
      permission: 'depot:view'
    },
    component: () => import('../views/depot/result.vue')
  },
  {
    path: '/preplanResultForPad',
    name: 'ResultForPad',
    meta: {
      needLogin: true,
      title: 'Preplan Result',
      permission: 'result_pad:view'
    },
    component: () => import('../views/depot/resultPad.vue')
  },
  {
    path: '/preplanResultForTablet',
    name: 'ResultForTablet',
    meta: {
      needLogin: true,
      title: 'Preplan Result',
      permission: 'result_pad:view'
    },
    component: () => import('../views/depot/resultTablet.vue')
  },
  {
    path: '/404',
    name: '404',
    meta: {
      needLogin: false,
      title: '找不到页面'
    },
    component: () => import('../views/404.vue')
  },
  {
    path: '/403',
    name: '403',
    meta: {
      needLogin: false,
      title: '没有权限'
    },
    component: () => import('../views/403.vue')
  },
  {
    path: '/guestvisit',
    name: 'Guest',
    meta: {
      needLogin: false,
      title: 'Guest'
    },
    component: () => import('../views/guest.vue')
  },
  {
    path: '/scan',
    name: 'ScanView',
    meta: {
      needLogin: false,
      title: 'ScanView'
    },
    component: () => import('../views/depot/BarcodeScan.vue')
  },
  {
    path: '/mobileLogin',
    name: 'SignIn',
    meta: {
      needLogin: false,
      title: 'Sign In'
    },
    component: () => import('../views/login/mobileLogin.vue')
  },
  {
    path: '/3dView',
    name: '3D',
    meta: {
      needLogin: false,
      title: '3D'
    },
    component: () => import('../views/3d/3d.vue')
  },
  {
    path: '/rtsp',
    name: 'RTSP',
    meta: {
      needLogin: false,
      title: 'RTSP'
    },
    component: () => import('../views/rtsp/rtsp.vue')
  },
  {
    path: '/flightListForMobile',
    name: 'FlightsForMobile',
    meta: {
      needLogin: true,
      title: 'Flights',
      permission: 'result_phone:view'
    },
    component: () => import('../views/depot/mobile/flightList.vue')
  },
  {
    path: '/preplanResultForMobile',
    name: 'PreplanResultForMobile',
    meta: {
      needLogin: true,
      title: 'Result',
      permission: 'result_phone:view'
    },
    component: () => import('../views/depot/mobile/preplanResultForMobile.vue')
  },
]




const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}



const hasPermission = (code) => {

  // return true;
  const permissions = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')).permissions
  return (permissions ?? []).find(item => item == code)
}


router.beforeEach((to, from, next) => {
  NProgress.start()

  if (to.matched && to.matched.length > 0) {


    document.title = `${to.meta.title} | ToLead-OMS`
    if (to.meta.needLogin) {
      if (localStorage.getItem('access_token')) {
        // 已登录，判断权限
        const role = hasPermission(to.meta.permission)
      
        if (role) {
          next()
        } else {
          next('/403')
        }
      } else {
        // 未登录，打开登录页面
        next('/login')
      }
    } else {
      next()
    }
  } else {
    next('/404')
  }
})

router.afterEach(() => {
  NProgress.done()
})




export default router
