module.exports = {
  system: {
    changeLocale: '切换语言',
    create: '创建',
    import: '导入',
    delete: '删除',
    edit: '编辑',
    detail: '详情',
    index: '序号',
    operation: '操作',
    search: '查询',
    reset: '重置',
    confirm: '确定',
    select: "选择",
    all: "全部",
    selectAll: "全选",
    save: '保存',
    cancel: '取消',
    export: '导出',
    back: '返回',
    pleaseInput: '请输入{0}',
    pleaseSelect: '请选择{0}',
    success: '成功',
    fail: '失败',
    continue: '是否继续',
    enable: '启用',
    disable: '禁用',
    emptySelection: '请选择一条记录',
    option: '标签选项',
    closeOthers: '关闭其他',
    closeAll: '关闭所有',
    or: '或',
    hour: '小时',
    minuate: '分钟',
    second: '秒',
    yes: '是',
    no: '否',
    hello: '您好：{0}',
    enter: '打开系统',
    noData: "暂无数据",
    total: "合计",
    clear: "清空",
    take: "拍照"
  },
  menu: {
    home: '主页',
    product: 'Product',
    service: '产品服务',
    about: '关于我们',
    order: 'Order',
    bill: 'Bill',
    my: '我的',
    customs: "Customs",
    dashboard: "Home",
    news: "News",
    contact: "联系我们",
    appointment: "Appointment",
    preplan: "预配"
  },
  login: {
    title: '登录',
    mobile: '手机号码',
    vcode: '验证码',
    getVCode: '获取验证码',

    login: '登录',
    logout: '退出登录',
    loginSuccess: '登录成功',
    logoutSuccess: '退出登录成功',
    noAuth: '权限不足，请联系管理员',

    loginType: ['手机登录', '帐号登录'],
    account: '帐号',
    password: '密码'

  },
  404: {
    tip: '您所访问的页面不存在',
    backToHome: '返回首页',
    backToPre: '返回上一页'
  },
  403: {
    tip: '您没有权限访问该页面哦',
    backToHome: '返回首页',
    backToPre: '返回上一页'
  },
  preplan: {
    title: "预配",
    airModel: "飞机设定",
    plateModel: "机板设定",
    groupBy: "分组",
    consignee: "收货人",
    dest: "目的地",
    single: "单一主单",
    boxFillScale: "纸箱装载率",
    plateHeightLimit: "单板限高率",
    outline: "外径",
    preplan: "预配",
    match: "匹配计划",
    result: "结果",
    mawb: "主单",
    filters: "筛选",
    filterName: "名称",
    plate: "板",
    defaultPol: "默认起运港",
    flightDate: "起飞日期",
    all: "全部",
    volumeLoadingRate: "体积装载率",
    weightLoadingRate: "重量装载率",
    import: "导入",
    update: "更新",
    downloadTemplate: "下载模板",
    selected: "选中的",
    totalPieces: "总计件数",
    palletPieces: "托盘件数",
    cartonPieces: "纸箱件数",
    preplaned: "已预配",
    deleteMawbConfirm: "是否确认删除该主单?",
    create: "创建航班",
    mawbTable: {
      MAWB: "主单号",
      POL: "起运港",
      DEST: "目的地",
      Pieces: "件",
      Weight: "毛重",
      Volume: "体积",
      ACT: "货站",
      Consignee: "收货人",
      Notify: "通知人",
      Requirement: "现场要求",
      flightDate: "起飞日期",
      Inbound: "到仓日期",
      cargoTypes: "货物类型",
      Price: "运费",
      DIMS: "货物尺寸",
      Ratio: "重抛比",
      Remarks: "备注",
      Batch: "批次"
    },
    plateTable: {
      plateType: "板类型",
      maxVolume: "最大体积",
      maxWeight: "最大毛重",
      units: "数量"
    },
    airlineCreateModel: {
      title: "创建航线",
      title2: "编辑航线",
      pol: "起运港",
      pod: "目的港",
      flightDate: "起飞日期",
      airPlane: "机型",
      airCompany: "航司",
      flightnr: "航班",

    },
    airlineSelectModel: {
      title: "航线选择",
      flightnr: "航班",
      flightDate: "起飞日期",
      model: "机型",
      air: "航司",
      volumeLoadingRate: "体积装载率",
      weightLoadingRate: "重量装载率",
      pol: "起运港",
      pod: "目的港",
      deleteConfirm: "是否确认删除该航班?"
    },
    modifyPalletModel: {
      title: "编辑板",
      title2: "选择板",
      title3: "编辑板",
      palletType: "板类型",
      pcsVol: "容积",
      pcsGw: "载重",
      units: "数量",
      plateNo: "板编号",
      plateType: "板类型",
      overHeight: "超高",
      ow: "含板重量",
      tw: "板重",
      mawb: "主单号",
      pieces: "件数",
      deleteConfirm: "是否确认删除该主单?",
      name: "名称",
      size: "大小",
      preview: "预览",
      additional:"增值项",
      quantity:"数量",
      deleteConfirm2: "是否确认删除该照片?"
    },
    createMawbModel: {
      title: "新建主单",
      title2: "编辑主单",
      MAWB: "主单号",
      POL: "起运港",
      DEST: "目的地",
      Pieces: "件",
      Weight: "毛重",
      Volume: "体积",
      ACT: "货站",
      Consignee: "收货人",
      Notify: "通知人",
      Requirement: "现场要求",
      flightDate: "起飞日期",
      Inbound: "到仓日期",
      Price: "售价",
      DIMS: "货物尺寸",
      Remarks: "备注",
      Batch: "批次"

    },
    modalListModel: {
      title: "机型列表",
      model: "机型",
      modelName: "机型名称",
      air: "航司",
      volume: "体积",
      weight: "重量",
      palletQuantity: "板数量",
      default: "是否默认机型",
      deleteModelConfirm: "是否确认删除该机型?",
    },
    modelCreateModal: {
      title: "创建机型",
      title2: "编辑机型",
      template: "模板",
      model: "机型",
      modelName: "机型名称",
      air: "航司",
      plateType: "板类型",
      pcsVol: "体积",
      pcsGw: "重量",
      pcs: "数量"
    },
    modelPlateModal: {
      title: "机板设定",
      airPlane: "机型",
      plateName: "板名称",
      plateType: "板类型",
      uld: "ULD",
      loadingArea: ["装载区", "高", "低"],
      l1: "长边(厘米)",
      l2: "短边(厘米)",
      w: "宽(厘米)",
      h1: "长高(厘米)",
      h2: "短高(厘米)",
      gw: "重量(公斤)",
      vol: "体积(立方米)",
      deletePlateConfirm: "是否确认删除该板类型?"
    },
    flightViewModal: {
      title: "航班",
      flightNr: "航班号",
      flightDate: "起飞日期",
      airCompany: "航司",
      pol: "起运港",
      pod: "目的港",
      totalMawb: "主单总数",
      totalCw: "CW总数",
      totalVol: "总体积",
      takeOff: "起飞",

    },
    flightDetailInfoModal: {
      tabMawb: "按主单",
      tabPlate: "按板",
      mawb: "主单号",
      pcs: "件数",
      gwgt: "重量",
      pol: "起运港",
      dest: "目的地",
      plates: "板",
      no: "序号",
      plateType: "板类型",
      grossWeight: "毛重",
      tareWeight: "皮重",
      pieces: "件数",
      netWeight: "净重",
      picture: "照片"
    },
    barcodeScanModal: {
      title: "扫码",
      tips: "将二维码/条码放入框内，即自动扫描",
      title2: "拍照"
    },
    matchSelectModal: {
      title: "匹配计划列表",
      plan: "方案",
      planNo: "计划编号",
      plates: "板",
      mawbs: "主单",
      act: "货站",
      consignee: "收货人",
      dest: "目的地",
      remainVol: "剩余体积",
      remainWeight: "剩余重量",
      choose: "选择"
    },
    mawbSelectModal: {
      title: "切换主单",
      title2: "选择主单",
      selectedMawb: "选择的主单",
      totalPieces: "总数",
      weight: "重量",
      volume: "体积",
      flightDate: "起飞日期",
      mawb: "主单号"
    },
    plateChangeModal: {
      title: "切换板",
      plateType: "板类型",
      maxWeight: "最大载重",
      maxVolume: "最大容积"
    },
    preplanModal: {
      title: "概览",
      boxFillScale: "纸箱装载率",
      fill: "填充",
      average: "平均",
      recommend: "推荐",
      mawb: "主单",
      total: "总数",
      assigned: "已分配",
      outstanding: "未分配",
      pallet: "托盘",
      carton: "散装",
      palletType: "托盘类型",
      preplanVol: "计划体积",
      preplanWeight: "计划重量",
      elevate: "垫高",
      cm: "高度",
      kg: "重量",
      amount: "运费",
      platePieces: "板件数",
      continue: "是否继续",
      continueTip: "部分主单尚未计算完成。"
    },
    recommendListModal: {
      title: "推荐列表",
      palletOutstandingVol: "托盘剩余容积",
      weight: "载重",
      plan: "方案",
      mawbVol: "主单体积",
      mawbWeight: "主单重量",
      outstandingVol: "未分配体积",
      outstandingWeight: "未分配重量",
      choose: "选择"
    }


  },
  result: {
    mawb: "主单号",
    gw: "重量",
    vol: "体积",
    amount: "运费",
    pts: "托盘数",
    ctns: "散货数",
    totalGw: "航班重量",
    totalVol: "航班体积",
    totalAmount: "总运费",
    totalPlateAmount: "板运费",
    totalFlightAmount: "航班运费",
    totalMawb: "总主单数",
    totalPlate: "总板数",
    export: "导出",
    refresh: "刷新",

  },
  mobile: {
    title: "用户登录",
    signIn: "登录",
    account: "帐号",
    password: "密码",
    flight: "航班",
    logout: "登出",
    search: "查询",
    mawb: "主单",
    noData: "加载中"
  },
  my: {
    title: '我的',
    active: '已生效',
    inactive: '已失效',
    contacts: {
      title: '人员',
      name: '姓名',
      account:"帐号",
      orgName:"所属组织",
      mobile: '手机号',
      role: '角色',
      mail: '邮箱',
      dataAuth: '数据权限',
      enable: '是否启用',
      orgAuth: '本机构数据',
      userAuth: '个人创建数据',
      create: '新增用户',
      createSuccess: '新增用户成功',
      update: '编辑用户',
      updateSuccess: '编辑用户成功',
      password: '密码',
      passwordConfirm: '确认密码',
      resetPassword: '重置密码',
      deleteConfirm:"删除后无法恢复，是否确认？"
    },
    org:{
      title:"组织",
      orgCode:"组织编码",
      orgName:"组织名称",
      orgType:["","一级","二级","三级","组织类型"],
      supOrgName:"上级组织",
      dataAuth:["自己","全部","数据权限"],
      mgtAugh:["自己","全部","后台权限"],
      create: '新增组织',
      createSuccess: '新增组织成功',
      update: '编辑组织',
      updateSuccess: '编辑组织成功',
      deleteConfirm:"删除后无法恢复，是否确认？"
    },
    role:{
      title:"权限",
      name:"角色名称",
      module:"所属模块",
      orgName:"所属公司",
      type:["通用","自定义","类型"],
      enable:"是否启用",
      create: '新增角色',
      createSuccess: '新增角色成功',
      update: '编辑角色',
      updateSuccess: '编辑角色成功',
      deleteConfirm:"删除后无法恢复，是否确认？"
    },
    additional:{
      title:"耗材",
      create: '新增耗材',
      createSuccess: '新增耗材成功',
      update: '编辑耗材',
      updateSuccess: '编辑耗材成功',
      deleteConfirm:"删除后无法恢复，是否确认？"
    },
    pwd:{
      update: '修改密码',
      updateSuccess: '密码修改成功',
      oldPassword:"旧密码",
      newPassword:"新密码"
    }
  },
  myValidate: {
    login: [
      '请输入正确的手机号码'
    ],
    register: [
      '请输入正确的手机号码'
    ],
    my: [
      '请输入正确的手机号码',
      '请输入正确的邮件地址',
      '请输入正确的身份证号码',
      '两次输入的密码不一致'
    ]
  },
}
