<template>
  <div>
    <v-header />
    <div class="content-box">
      <div class="content">
        <router-view v-slot="{ Component }">
          <transition name="move" mode="out-in">
            <component :is="Component" />
          </transition>
        </router-view>
      </div>
    </div>
  </div>
</template>
<script>
import vHeader from "@/components/Header";
export default {
  components: {
    vHeader,
  },
  computed: {
  },
};
</script>
