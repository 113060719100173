
const onlyNumber = {
    inserted(el, vDir, vNode) {
        // vDir.value 有指令的参数
        let content
        // 设置输入框的值,触发input事件,改变v-model绑定的值
        const setVal = val => {
            if (vNode.componentInstance) {
                // 如果是自定义组件就触发自定义组件的input事件
                //   vNode.componentInstance.$emit('input', val)   //elementUI
                vNode.componentInstance.$emit('change.value', val) //antd
            } else {
                // 如果是原生组件就触发原生组件的input事件
                el.value = val
                el.dispatchEvent(new Event('input'))
            }
        }
        // 按键按下=>只允许输入 数字/小数点/减号
        el.addEventListener('keypress', event => {
            const e = event || window.event
            const inputKey = String.fromCharCode(typeof e.charCode === 'number' ? e.charCode : e.keyCode)
            const re = /\d|\.|-/
            content = e.target.value
            // 定义方法,阻止输入
            function preventInput() {
                if (e.preventDefault) {
                    e.preventDefault()
                } else {
                    e.returnValue = false
                }
            }
            if (!re.test(inputKey) && !e.ctrlKey) {
                preventInput()
            } else if (content.indexOf('.') > 0 && inputKey === '.') {
                // 已有小数点,再次输入小数点
                preventInput()
            }
        })
        // 按键弹起=>并限制最大最小
        el.addEventListener('keyup', event => {
            const e = event || window.event
            content = parseFloat(e.target.value)
            if (!content) {
                content = 0.00
            }
            let arg_max = ''
            let arg_min = ''
            if (vDir.value) {
                arg_max = parseFloat(vDir.value.max)
                arg_min = parseFloat(vDir.value.min)
            }
            if (arg_max !== undefined && content > arg_max) {
                setVal(arg_max)
                content = arg_max
            }
            if (arg_min !== undefined && content < arg_min) {
                setVal(arg_min)
                content = arg_min
            }
        })
        // 失去焦点=>保留指定位小数
        el.addEventListener('focusout', event => { // 此处会在 el-input 的 @change 后执行
            const e = event || window.event
            content = parseFloat(e.target.value)
            if (!content) {
                content = 0.00
            }
            let arg_precision = 0// 默认保留至整数
            if (vDir.value.precision) {
                arg_precision = parseFloat(vDir.value.precision)
            }
            e.target.value = content.toFixed(arg_precision)
            setVal(e.target.value)
            // -- callback写法1
            // vNode.data.model.callback = ()=>{
            //     e.target.value = content.toFixed(arg_precision)
            // }
            // vNode.data.model.callback();
            // -- callback 写法2
            // vNode.data.model.callback(
            //     e.target.value = content.toFixed(arg_precision)
            // )
        })
    }
}

const install = Vue => {
    Vue.directive('onlyNumber', onlyNumber)
}
/*
  Vue.use( plugin )
  安装 Vue.js 插件。如果插件是一个对象，必须提供 install 方法。
  如果插件是一个函数，它会被作为 install 方法。install 方法调用时，会将 Vue 作为参数传入。
  该方法需要在调用 new Vue() 之前被调用。
  当 install 方法被同一个插件多次调用，插件将只会被安装一次。
*/

if (window.Vue) {
    window['onlyNumber'] = onlyNumber
    Vue.use(install); // eslint-disable-line
}

onlyNumber.install = install
export default onlyNumber