import axios from "axios";
import { message } from 'ant-design-vue';
import JSONbig from 'json-bigint'
import dateUtil from '../utils/dateUtils'

const service = axios.create({
  timeout: 180000,
  // baseURL: process.env.VUE_APP_ADMINAPI,
  transformResponse: [
    data => {
      try {
        return JSONbig.parse(data);
      } catch (err) {
        return data;
      }
    },
  ],
});

service.interceptors.request.use(
  (config) => {
    if (localStorage.getItem("access_token")) {
      config.headers = {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: "Bearer " + localStorage.getItem("access_token"),
        TimeZone: dateUtil.getTZ(),
        GMT: dateUtil.getGMT()
      };
    } else {
      config.headers = {
        "Content-Type": "application/json; charset=UTF-8",
        TimeZone: dateUtil.getTZ(),
        GMT: dateUtil.getGMT()
      };
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject();
  }
);

service.interceptors.response.use(
  (response) => {

    if (response && response.config && response.config.responseType == 'blob') {
      return response.data;
    }
   
    if (response.status === 200 && response.data) {
      if (response.data.code == 200) {
        return response.data;
      } else if (response.data.code == 401) {
        message.error("Token expired , relogin!")
        localStorage.removeItem("access_token");
        setTimeout(() => {
          window.location.href = "/login";
        }, 1000);
        return Promise.reject(response.data);
      } else if (response.data.code == 500) {
        message.warning(response.data.message);
        return { code: -1 }
      } else {
        message.warning(response.data.message);
        return Promise.reject(response.data);
      }
    } else {
      // message.warning(JSON.stringify(response));
      return Promise.reject("System Error");
    }
  },
  (error) => {
    // message.warning(JSON.stringify(error));
    if (error.response && error.response.status == 401) {
      message.error("Token expired , relogin!")
      // localStorage.removeItem("ms_username");
      localStorage.removeItem("access_token");
      // localStorage.removeItem("user");

      setTimeout(() => {
        window.location.href = "/login";
      }, 1000);
    } else {
      message.error(error.response.data.msg);
    }
    return Promise.reject("Network Error");
  }
);




export default service;