module.exports = {
  system: {
    changeLocale: 'Switch Language',
    create: 'Create',
    import: 'Import',
    delete: 'Delete',
    edit: 'Edit',
    detail: 'Detail',
    index: 'Index',
    operation: 'Operation',
    search: 'Search',
    reset: 'Reset', 
    confirm: 'Confirm',
    select:"Select",
    all:"ALL",
    selectAll:"Select all",
    save: 'Save',
    cancel: 'Cancel',
    export: 'Export',
    back: 'Back',
    pleaseInput: 'Pls input {0}',
    pleaseSelect: 'Pls select {0}',
    success: 'success',
    fail: 'fail',
    continue: 'Continue',
    enable: 'Enable',
    disable: 'Disable',
    emptySelection: 'Please select a record',
    option: 'Option',
    closeOthers: 'Close Others',
    closeAll: 'Close All',
    or: 'OR',
    hour: 'h',
    minuate: 'm',
    second: 's',
    yes: 'Yes',
    no: 'No',
    hello: 'hi {0}',
    enter: 'Enter the system',
    noData:"No Data",
    total:"Total",
    clear:"Clear",
    take:"Take"
  },
  menu: {
    home: 'Home',
    product: 'Product',
    service: 'Service',
    about: 'About',
    order: 'Order',
    bill: 'Bill',
    my: 'My',
    customs: "Customs",
    dashboard:"Home",
    news:"News",
    contact:"Contact",
    appointment:"Appointment",
    preplan: "Preplan"
  },
  login: {
    title: 'Login',
    mobile: 'Mobile',
    vcode: 'Verify code',
    getVCode: 'Get verify code',

    login: 'Login',
    logout: 'Logout',
    loginSuccess: 'Login successful',
    logoutSuccess: 'Logout successful',
    noAuth: 'Permission denied, pls contact manager',
    loginType: ['Mobile login', 'Account login'],
    account: 'Account',
    password: 'Password'
  },
  404: {
    tip: 'Page not exist',
    backToHome: 'Back to home',
    backToPre: 'Back to previous page'
  },
  403: {
    tip: 'No permission to access this page',
    backToHome: 'Back to home',
    backToPre: 'Back to previous page'
  },
  preplan:{
    title: "Preplan",
    airModel: "Air Model",
    plateModel:"Plate Model",
    groupBy: "Group By",
    consignee:"Consignee",
    dest:"DEST",
    single:"Single",
    boxFillScale:"Box Fill Scale",
    plateHeightLimit:"Plate Height Limit",
    outline:"Outline",
    preplan: "Preplan",
    match: "Match Plan",
    result: "Result",
    mawb: "MAWB",
    filters:"Filters",
    filterName:"Name",
    plate:"Plate",
    defaultPol:"Default Pol",
    flightDate: "Flight date",
    all:"ALL",
    volumeLoadingRate:"Volume loading rate",
    weightLoadingRate:"Weight loading rate",
    import:"Import",
    update:"Update",
    downloadTemplate:"Download template",
    selected:"Selected ",
    totalPieces:"Total pieces",
    palletPieces:"Pallet pieces",
    cartonPieces:"Carton pieces",
    preplaned:"Preplaned",
    deleteMawbConfirm:"Are you sure to delete this mawb?",
    create:"Create",
    mawbTable:{
      MAWB: "MAWB",
      POL: "POL",
      DEST: "DEST",
      Pieces: "Pieces",
      Weight: "Weight",
      Volume: "Volume",
      ACT: "ACT",
      Consignee: "Consignee",
      Notify: "Notify",
      Requirement: "Requirement",
      flightDate: "Flight date",
      Inbound: "Inbound",
      cargoTypes: "Cargo types",
      Price: "Price",
      DIMS: "DIMS",
      Ratio: "Ratio",
      Remarks: "Remarks",
      Batch: "Batch"
    },
    plateTable:{
      plateType:"Plate type",
      maxVolume:"Max volume",
      maxWeight:"Max weight",
      units:"Units"
    },
    airlineCreateModel:{
      title:"Create air line",
      title2:"Update air line",
      pol:"POL",
      pod:"POD",
      flightDate:"Flight date",
      airPlane:"Air plane",
      airCompany:"Air company",
      flightnr:"Flightnr",
    },
    airlineSelectModel:{
      title:"Air Line Select",
      flightnr:"Flightnr",
      flightDate:"Flight Date",
      model:"Model",
      air:"Air",
      volumeLoadingRate:"Volume loading rate",
      weightLoadingRate:"Weight loading rate",
      pol:"POL",
      pod:"POD",
      deleteConfirm:"Are you sure to delete this flight?"
    },
    mawbListModel:{
      title:"MAWB List"
    },
    modifyPalletModel:{
      title:"Modify Pallets",
      title2:"Select Pallets",
      title3:"Modify result",
      palletType:"Pallet type",
      pcsVol:"PCS Vol",
      pcsGw:"PCS GW",
      units:"Units",
      plateNo:"Plate No",
      plateType:"Plate type",
      overHeight:"Over height",
      ow:"OW",
      tw:"TW",
      mawb:"MAWB",
      pieces:"Pieces",
      deleteConfirm:"Are you sure to delete this mawb?",
      name:"Name",
      size:"Size",
      preview:"Preview",
      additional:"Additional",
      quantity:"Quantity",
      deleteConfirm2:"Are you sure to delete this photo?"

    },
    createMawbModel:{
      title:"New MAWB",
      title2:"Modify MAWB",
      MAWB: "MAWB",
      POL: "POL",
      DEST: "DEST",
      Pieces: "Pieces",
      Weight: "Weight",
      Volume: "Volume",
      ACT: "ACT",
      Consignee: "Consignee",
      Notify: "Notify",
      Requirement: "Requirement",
      flightDate: "Flight date",
      Inbound: "Inbound",
      cargoTypes: "Cargo types",
      Price: "Price",
      DIMS: "DIMS",
      Ratio: "Ratio",
      Remarks: "Remarks",
      Batch: "Batch"

    },
    modalListModel:{
      title:"Model List",
      model:"Model",
      modelName:"Model name",
      air:"Air",
      volume:"Volume",
      weight:"Weight",
      palletQuantity:"Pallet quantity",
      default:"Default model",
      deleteModelConfirm:"Are you sure to delete this model?",
    },
    modelCreateModal:{
      title:"Create model",
      title2:"Modify model",
      template:"Template",
      model:"Model",
      modelName:"Model name",
      air:"Air",
      plateType:"Plate type",
      pcsVol:"PCS Vol",
      pcsGw:"PCS GW",
      pcs:"PCS"
    },
    modelPlateModal:{
      title:"Plate model",
      airPlane:"Air plane",
      plateName:"Plate name",
      plateType:"Plate type",
      uld:"ULD",
      loadingArea:["Loading area","High","Low"],
      l1:"L1(CM)",
      l2:"L2(CM)",
      w:"W(CM)",
      h1:"H1(CM)",
      h2:"H2(CM)",
      gw:"GW(KG)",
      vol:"VOL(CBM)",
      deletePlateConfirm:"Are you sure to delete this plate?"
    },
    flightViewModal:{
      title:"Flights",
      flightNr:"Fligntnr",
      flightDate:"Flight date",
      airCompany:"Air company",
      pol:"POL",
      pod:"POD",
      totalMawb:"Total Mawb",
      totalCw:"Total CW",
      totalVol:"Total Vol",
      takeOff:"Take off",
      
    },
    flightDetailInfoModal:{
      tabMawb:"By Mawb",
      tabPlate:"By Plate",
      mawb:"MAWB",
      pcs:"PCS",
      gwgt:"G.wGT",
      pol:"POL",
      dest:"DEST",
      plates:"Plate",
      no:"No",
      plateType:"Plate type",
      grossWeight:"Gross weight",
      tareWeight:"Tare weight",
      pieces:"Pieces",
      netWeight:"Net weight",
      picture:"Picture"
    },
    barcodeScanModal:{
      title:"Scan",
      tips:"Put the QR code / barcode into the box",
      title2:"Take picture"
    },
    matchSelectModal:{
      title:"Plan match list",
      plan:"PLAN",
      planNo:"Plan No",
      plates:"Plates",
      mawbs:"Mawbs",
      act:"ACT",
      consignee:"Consignee",
      dest:"DEST",
      remainVol:"Remain volume",
      remainWeight:"Remain weight",
      choose:"Choose"
    },
    mawbSelectModal:{
      title:"Mawb change",
      title2:"Mawb selection",
      selectedMawb:"Selected Mawb",
      totalPieces:"Total Pieces",
      weight:"Weight",
      volume:"Volume",
      flightDate:"Flight date",
      mawb:"MAWB"
      
    },
    plateChangeModal:{
      title:"Pallet change",
      plateType:"Pallet type",
      maxWeight:"Max weight",
      maxVolume:"Max volume"
    },
    preplanModal:{
      title:"Overview",
      boxFillScale:"Box fill scale",
      fill:"Fill",
      average:"Average",
      recommend:"Recommend",
      mawb:"MAWB",
      total:"Total",
      assigned:"Assigned",
      outstanding:"Outstanding",
      pallet:"Pallet",
      carton:"Carton",
      palletType:"Pallet type",
      preplanVol:"Preplan volume",
      preplanWeight:"Preplan weight",
      elevate:"Elevate",
      cm:"CM",
      kg:"KG",
      amount:"Amount",
      platePieces:"Plate pieces",
      continue:"Continue?",
      continueTip:"Some mawbs have not been calculated yet."
      
    },
    recommendListModal:{
      title:"Recommend list",
      palletOutstandingVol:"Pallet outstanding Volume",
      weight:"Weight",
      plan:"Plan",
      mawbVol:"MAWB Volume",
      mawbWeight:"MAWB Weight",
      outstandingVol:"Outstanding Volume",
      outstandingWeight:"Outstanding Weight",
      choose:"Choose"
    }
    
  },
  result:{
    mawb:"MAWB",
    gw:"GW",
    vol:"VOL",
    amount:"Amount",
    pts:"PTS",
    ctns:"CTNS",
    totalGw:"Flight GW",
    totalVol:"Flight Vol",
    totalAmount:"Total amount",
    totalPlateAmount: "Plate amount",
    totalFlightAmount: "Flight amount",
    totalMawb:"Total mawb",
    totalPlate:"Total plate",
    export:"Export",
    refresh:"Refresh"
  },
  mobile:{
    title:"Sign In",
    signIn:"Sign In",
    account:"account",
    password:"password",
    flight:"Flight",
    logout:"Logout",
    search:"Search",
    mawb:"MAWB",
    noData:"No Data"
  },
  my:{
    title: 'My',
      active: 'Actived',
      inactive: 'Inactive',
    contacts: {
      title: 'Users',
      name: 'Name',
      account:"Account",
      orgName:"Orgnization",
      mobile: 'Mobile',
      role: 'Role',
      mail: 'E-mail',
      dataAuth: 'Authority',
      enable: 'Is enable',
      orgAuth: 'Organization data',
      userAuth: 'Personal data',
      create: 'Create user',
      createSuccess: 'User create successful',
      update: 'Update user',
      updateSuccess: 'User update successful',
      password: "Password",
      passwordConfirm: "Confirm password",
      resetPassword: "Reset password",
      deleteConfirm:"Can NOT be undone, confirm?"
    },
    org:{
      title:"Organization",
      orgCode:"Orgnization code",
      orgName:"Orgnization name",
      orgType:["","Level one","Level two","Level three","Level"],
      supOrgName:"Parent organization",
      dataAuth:["Self","All","Data auth"],
      mgtAugh:["Self","All","Permission"],
      create: 'Create orgnization',
      createSuccess: 'Orgnization create successful',
      update: 'Update orgnization',
      updateSuccess: 'Orgnization update successful',
      deleteConfirm:"Can NOT be undone, confirm?"
    },
    role:{
      title:"Authority",
      name:"Role name",
      module:"Module",
      orgName:"Orgnization",
      type:["Universal","Custom","Type"],
      enable:"Enable",
      create: 'Create role',
      createSuccess: 'Role create successful',
      update: 'Update role',
      updateSuccess: 'Role update successful',
      deleteConfirm:"Can NOT be undone, confirm?"
    },
    additional:{
      title:"Additional",
      create: 'Create additional',
      createSuccess: 'Additional create successful',
      update: 'Update additional',
      updateSuccess: 'Additional update successful',
      deleteConfirm:"Can NOT be undone, confirm?"
    },
    pwd:{
      update: 'Modify password',
      updateSuccess: 'Password updated',
      oldPassword:"Old password",
      newPassword:"New password"
    }
  },
  myValidate: {
    login: [
      'Please input correct mobile'
    ],
    register: [
      'Please input correct mobile'
    ],
    my: [
      'Please input correct mobile',
      'Please input correct email',
      'Please input correct identification',
      "Not same password"
    ]
  },
}
