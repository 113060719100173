const state = {
  tagsList: [],
  collapse: false,
  language: 'en',
  menuList: [
    {
      id: 1,
      code: 'home'
    },
    {
      id: 2,
      code: 'service'
    },
    {
      id: 3,
      code: 'news'
    },
    {
      id: 4,
      code: 'about'
    },
    {
      id: 5,
      code: 'contact'
    }
  ],
  loginMenuList: [
    // {
    //   id: 1,
    //   code: 'order',
    //   permission: 'order:view',
    //   name:"Order"
    // },
    // {
    //   id: 2,
    //   code: 'appointment',
    //   permission: 'appointment:view',
    //   name:"Appointment"
    // },
    // {
    //   id: 3,
    //   code: 'bill',
    //   permission: 'bill:view',
    //   name:"Bill"
    // },
   
    {
      id: 5,
      code: 'preplan',
      permission: 'depot:view',
      name:"Preplan"
    },
    {
      id: 6,
      code: 'preplanResultForPad',
      permission: 'result_pad:view',
      name:"Result"
    },
    {
      id: 4,
      code: 'my',
      permission: 'mine:view',
      name:"My"
    },
    {
      id: 7,
      code: 'preplanResultForMobile',
      permission: 'result_phone:view',
      name:"Result"
    },
  ],
  trackNo:"",
  mawbId:0
}

const getters = {
  currentLocale (state) {
    return localStorage.getItem('locale') ? localStorage.getItem('locale') : state.language
  },
  trackNo (state) {
    return state.trackNo
  }
}

const mutations = {
  delTagsItem (state, data) {
    state.tagsList.splice(data.index, 1)
  },
  setTagsItem (state, data) {
    state.tagsList.push(data)
  },
  clearTags (state) {
    state.tagsList = []
  },
  closeTagsOther (state, data) {
    state.tagsList = data
  },
  closeCurrentTag (state, data) {
    for (let i = 0, len = state.tagsList.length; i < len; i++) {
      const item = state.tagsList[i]
      if (item.path === data.$route.fullPath) {
        if (i < len - 1) {
          data.$router.push(state.tagsList[i + 1].path)
        } else if (i > 0) {
          data.$router.push(state.tagsList[i - 1].path)
        } else {
          data.$router.push('/')
        }
        state.tagsList.splice(i, 1)
        break
      }
    }
  },
  // 侧边栏折叠
  hadndleCollapse (state, data) {
    state.collapse = data
  },

  // 设置语言
  setLanguage (state, data) {
    state.language = data
  },

  setTrackNo (state, data) {
    state.trackNo = data
  },

  setMawbId(state,data){
    state.mawbId = data
  }

}

const actions = {}

export default {
  state,
  getters,
  mutations,
  actions
}
