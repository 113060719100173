<template>
  <div :class="{ transparent: this.mode == 'transparent' }">
    <a-layout-header :style="{ position: 'fixed', zIndex: 1, width: '100%' }">
      <div class="logo">
        <router-link to="/home"
          ><img class="logo2" src="@/assets/logo.png" alt=""
        /></router-link>
      </div>

      <div class="funcRight">
        <!-- <a-input
          style="margin-right: 20px"
          @pressEnter="doTrack"
          @change="changeTrackNo"
          v-model="trackNo"
        >
          <a-icon slot="prefix" type="search" />
          <a-select v-model="trackType" slot="addonAfter" style="width: 100px">
            <a-select-option value="MAWB">MAWB</a-select-option>
            <a-select-option value="AWB">AWB</a-select-option>
          </a-select>
        </a-input> -->
        <!-- <a-popover>
          <template slot="content">
            <a-button type="link" @click="changeLocale('en')">English</a-button
            ><br />
            <a-button type="link" @click="changeLocale('zh-cn')"
              >简体中文</a-button
            >
          </template>
          <i class="iconfont tlicon-global" />
        </a-popover> -->
        <div v-if="!userName">
          <a-button shape="circle" icon="user" @click="toLogin" />
        </div>
        <div v-else>
          <a-button shape="circle" icon="user" @click="toLoginHome" />
        </div>
      </div>

      <a-menu
        theme="dark"
        mode="horizontal"
        :selectable="false"
        @click="onClickMenu"
      >
        <a-menu-item :key="1"> {{$t('menu.home')}} </a-menu-item>
        <a-menu-item :key="2"> {{$t('menu.service')}} </a-menu-item>
        <!-- <a-menu-item :key="3"> News </a-menu-item> -->
        <a-menu-item :key="4"> {{$t('menu.about')}} </a-menu-item>
        <a-menu-item :key="5"> {{$t('menu.contact')}} </a-menu-item>
      </a-menu>
    </a-layout-header>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
export default {
  props: ["mode"],
  data() {
    return {
      headerMode: "",
      routes: {
        1: "/home",
        2: "/service",
        3: "/news",
        4: "/about",
        5: "/contact",
      },
      userName: localStorage.getItem("ms_username"),
      trackType: "MAWB",
      trackNo: "",
    };
  },

  computed: {
    menuList() {
      return this.$store.state.system.menuList;
    },

    loginMenuList() {
      // return this.$store.state.system.loginMenuList;
      let fullMenuList = this.$store.state.system.loginMenuList;
      let permissions = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user")).permissions
        : [];
      if (!permissions) {
        permissions = [];
      }
      return fullMenuList.filter(
        (item) => permissions.indexOf(item.permission) >= 0
      );
    },
  },
  created() {},
  methods: {
    ...mapMutations(["setTrackNo"]),
    onClickMenu(item) {
      const route = this.routes[item.key];
      route && this.$router.push(route);
    },
    toLogin() {
      this.$router.push("/login");
    },
    changeTrackNo(e) {
      this.setTrackNo(e.target.value);
    },
    doTrack() {
      if (this.trackNo.trim() == "") return;
      if (this.trackType == "MAWB") {
        this.$router.push({
          path: "/track",
          query: { no: this.trackNo },
        });
      } else if (this.trackType == "AWB") {
        let no = this.trackNo.replaceAll("-", "");
        window.open(
          `http://lgg.tolead.com:33080/trackList?mawbNo=${no}`,
          "_blank"
        );
      }
    },
    toLoginHome() {
      if (this.loginMenuList.length > 0) {
        this.$router.push(`/${this.loginMenuList[0].code}`);
      } else {
        this.$message.warning("Permission denied, pls contact manager");
      }
    },
    changeLocale(language) {
      this.$i18n.locale = language;
      this.$store.commit("setLanguage", language);
      localStorage.setItem("locale", language);
      location.reload();
    },
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.logo {
  width: 120px;
  height: 31px;

  float: left;

  img {
    width: 120px;
    height: 30px;
  }

  .logo2 {
    width: 120px;
    height: 22px;
  }
}

.funcRight {
  float: right;
  display: flex;
  align-items: center;
  height: 64px;
  /deep/ .ant-btn {
    color: #ffffff;
    opacity: 0.65;
    margin-top: 20px;
  }
  /deep/ .ant-btn:hover {
    opacity: 1;
  }
  /deep/ .ant-divider-vertical {
    height: 20px;
    opacity: 0.65;
    margin-right: 15px;
  }
  .iconfont {
    font-size: 18px;
    color: white;
    margin: 0 10px;
    cursor: pointer;
    opacity: 0.65;
  }
  .iconfont:hover {
    opacity: 1;
  }
}
/deep/.ant-menu {
  font-size: 20px;
  float: left;
  padding-left: 100px;
  .ant-menu-item {
    line-height: 64px;
  }
}

.popContent {
  width: 630px;
  opacity: 0.95;
  padding: 30px;
  min-height: 300px;

  .iconfont {
    font-size: 30px;
    color: #7fd8c0;
  }

  /deep/ .ant-collapse {
    border: none;

    .ant-collapse-item {
      border: none;
      background: white;
      .ant-collapse-header {
        border-bottom: 4px solid #e6e6e6;
        background-color: white;
        width: 200px;
        span {
          font-size: 24px;
          font-weight: 400;
          color: #333333;
          line-height: 24px;
          margin-left: 35px;
        }
      }
      .ant-collapse-content {
        border: none;
      }
      .ant-collapse-content-box {
        .item {
          font-size: 22px;
          font-weight: 400;
          color: #333333;
          line-height: 22px;
          margin: 30px 40px;
        }
        .item:hover {
          color: red;
        }
      }
    }
  }

  .service_menu {
    font-size: 24px;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
    min-width: 220px;
    border-bottom: 3px solid #e6e6e6;
    display: flex;
    align-items: center;
    padding-bottom: 5px;

    .iconfont {
      margin-left: 10px;
      margin-right: 20px;
    }
  }

  .service_image {
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }

  .service_menu:hover {
    cursor: pointer;
    color: red;
  }
}

.transparent {
  /deep/ .ant-layout-header {
    background-color: transparent;
  }
  /deep/ .ant-menu {
    background-color: transparent;
  }
}

/deep/ .ant-btn {
  background-color: transparent;
  color: white;
  font-size: 20px;
  border: none;
  &:hover {
    background-color: transparent;
    color: white;
    border: none;
  }
  &:focus {
    background-color: transparent;
    color: white;
    border: none;
  }
  &:active {
    background-color: transparent;
    color: white;
    border: none;
  }
}
</style>
