<template>
  <div id="app">
    <a-config-provider :locale="locale">
      <router-view />
    </a-config-provider>
  </div>
</template>
<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import usEN from "ant-design-vue/lib/locale-provider/en_US";
export default {
  name: "App",
  computed: {
    locale() {
      switch (this.$store.getters.currentLocale) {
        case "zh-cn":
          return zhCN;
        case "en":
          return usEN;
        default:
          return zhCN;
      }
    },
  },
};
</script>
<style lang="less">
@import "./assets/css/main.css";
@import "./assets/css/color-dark.css";
#app {
  font-family: "Source Han Sans CN", "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
