import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd, { message } from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css';
import i18n from '@/plugins/i18n.js'
// import animated from 'animate.css'
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en'
import 'element-ui/lib/theme-chalk/index.css';
import "@/plugins/animation.js"
import "@/plugins/rem.js";
import onlyNumber from '@/plugins/onlyNumber'; //添加此行=>自定义全局指令
import Vant from 'vant';
import 'vant/lib/index.css';




Vue.config.productionTip = false

Vue.use(Antd);
Vue.use(Vant);
// Vue.use(animated)
Vue.use(ElementUI, { locale });
Vue.use(onlyNumber)
Vue.prototype.$form = Antd.Form
Vue.prototype.$message = message

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
