import Vue from 'vue';
import Vuex from 'vuex';
import system from "./system";
import customer from "./customer";
import keyvalue from "./keyvalue"
Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    system,
    customer,
    keyvalue
  },
});

export default store;