import mainServer from '@/api/index'

const state = {

    roleList: [],

};

const mutations = {

    setRoleList(state, data) {
        state.roleList = data;
    }
};

const actions = {

    loadRoleList({
        commit,
        state
    }, status) {
        mainServer.queryRoleList({}).then((res) => {
            commit('setRoleList', res.data.records);
        });
    },
};

export default {
    state,
    mutations,
    actions,
};
